<template>
  <div class='relative h-10'>
    <search-icon
      class='absolute text-gray-500 hover:text-gray-800 cursor-pointer'
      size='20'
      style='top: 10px; left: 12px;'
      @click='searchFocusOn()' />
    <input v-if='isSearching'
      v-model='searchText'
      ref='searchBox'
      type='search'
      :placeholder='placeholder'
      class='search-input border h-10 pl-10 focus:border-blue-400 focus:shadow w-full'
      style='border-radius: 1.5rem;'
      @focus='searchFocusOn()'
      @blur='searchFocusOff()'>
    <button v-if='activeCancelSearchButton' 
      class='ml-2 px-5 h-10 text-sm rounded-xl hover:shadow-md absolute right-0' 
      @click='clearSearchText()'>
      검색 취소
    </button>
  </div>
</template>

<script>
import debounce                   from 'debounce'
import { mapActions, mapState } from 'vuex'
import { SearchIcon }             from '@vue-hero-icons/outline'
import EventBus                   from '@/utils/event-bus'

export default {
  name: 'SearchAll',
  components: {
    SearchIcon,
  },
  data () {
    return {
      searchFocus: false,
      searchText: '',
    }
  },
  watch: {
    'searchText': {
      handler: function (newVal, oldVal) {
        if (newVal.trim() !== '' && newVal.trim() !== oldVal.trim()) {
          this.searchAll(newVal)
        }
      },
      immediate: false
    },
  },
  computed: {
    ...mapState([
      'isSearching',
    ]),
    placeholder () {
      return 'Search'
    },
    activeCancelSearchButton () {
      return this.searchFocus || this.searchText.trim()
    }
  },
  methods: {
    ...mapActions([
      'searchClose',
      'searchOpen',
      'sidepanelClose',
    ]),
    ...mapActions('investments', [
      'resetInvestmentSearchResults',
      'investmentSearch',
    ]),
    ...mapActions('contacts', [
      'resetContactSearchResults',
      'contactSearch',
    ]),
    ...mapActions('meetingNotes', [
      'resetMeetingNoteSearchResults',
      'meetingNoteSearch',
    ]),
    searchFocusOff () {
      this.searchText = this.searchText + ' '
      this.searchFocus = false
      if (this.searchText.trim() === '') {
        this.searchClose()
      }
    },
    searchAll (searchString) {
      let searchParams = {search_text: searchString, search_at: 'ALL'}
      this.investmentSearch(searchParams)
      // this.contactSearch(searchString)
      // this.meetingNoteSearch(searchString)
    },
    searchFocusOn () {
      this.searchText = this.searchText.trim()
      this.searchFocus = true
      this.searchOpen()
      this.sidepanelClose()
    },
    clearSearchText () {
      this.searchText = ''
      this.searchFocus = false
      this.resetInvestmentSearchResults()
      this.searchClose()
    }
  },
  created () {
    this.searchAll = debounce(this.searchAll, 500)
  },
  mounted () {
    EventBus.$on('close-search', () => this.clearSearchText())
    EventBus.$on('open-search', () => this.searchFocusOn())
  },
  beforeDestroy () {
    EventBus.$off('close-search')
    EventBus.$off('open-search')
  }
}
</script>

<style lang='scss' scoped>
input:focus {
    outline-width: 0;
}

.search-input {
  // @apply hidden;
  width: 100%;
}

  @media (min-width: 800px) {
    .search-input {
      @apply inline-block;
      width: 20rem;
    }
  }
</style>
