<template>
  <header class='h-16 lg:h-24 pl-2 pr-2 lg:px-8 flex flex-row items-center justify-between w-full'
    :class='searchClass'>
    <div class='flex-grow flex flex-row justify-start gap-x-2 items-center'>
      <menu-alt-1-icon @click='navMenuOpen' class='h-8 w-8 cursor-pointer' />
      <search-all :class='isSearchingClass' />
      <portal-target name='headerSpace' v-if='!isSearching'></portal-target>
    </div>
    <div v-if='!isSearching' class='flex-grow-0 flex flex-row items-center justify-end'>
      <approval-dialog 
        :hidden='isSearching' />
      <button v-if='!isSearching'
        @click='newMeetingNote' 
        class='ml-4 hidden lg:block border pl-3 pr-4 py-2 rounded-md text text-gray-700 border-gray-200 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <plus-circle-icon class='inline-block h-5' style='margin-top: -5px;' /> 
        Note
      </button>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { MenuAlt1Icon, PlusCircleIcon } from '@vue-hero-icons/outline'
import SearchAll       from '@/components/SearchAll.vue'
import ApprovalDialog  from '@/components/approvals/ApprovalDialog.vue'

export default {
  name: 'AppHeader',
  components: {
    MenuAlt1Icon,
    PlusCircleIcon,
    SearchAll,
    ApprovalDialog,
  },
  computed: {
    searchClass () {
      return (this.isSearching) ? 'headerSearchBackground' : ''
    },
    ...mapState([
      'isSearching',
    ]),
    isSearchingClass () {
      return (this.isSearching) ? 'flex-grow flex-shrink-0 w-auto' : 'flex-grow-0 w-12'
    },
  },
  methods: {
    ...mapActions([
      'navMenuOpen'
    ]),
    ...mapActions('meetingNotes', [
      'openNewMeetingNote',
    ]),
    newMeetingNote () {
      this.openNewMeetingNote()
    },
    openSearch () {
      console.log('open search')
    },
  },
}
</script>

<style lang='scss' scoped>
  .headerSearchBackground {
    background-color: #F5F5F5;
    // TODO: fix this need to figure out how
    position: sticky;
    top: 0;
    z-index: 10;
  }
</style>
